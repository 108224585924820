import {User} from "@/model/api/User";
import {usersService} from "@services/users.service";
import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";

import {UsersRoutesEnum} from "../../router";
import {authStore} from "@/modules/auth/store";
import {UserRoleEnum} from "@/model/enums/UserRoleEnum";
import {State} from "@/model/api/State";
import {SendMessageDialog} from "@components";

@Options({
    components: {
        SendMessageDialog
    }
})
export default class UserPage extends Vue {
    @Prop()
    readonly userId!: string;

    user: User = null;

    selectedState: string | State = null;

    displayMessageDialog: boolean = false;

    get actions() {
        const actions = [
            {
                label: 'Send message',
                icon: 'pi pi-send',
                command: () => {
                    this.displayMessageDialog = true;
                }
            }
        ];

        if (!this.isNew) {
            actions.push({
                label: 'Delete',
                icon: 'pi pi-trash',
                command: () => {
                    this.deleteUser();
                }
            });
        }

        return actions;
    }

    get tabs() {
        let i = 0;

        const tabs = [
            {
                label: "User data",
                icon: 'fas fa-clipboard-list',
                to: { name: UsersRoutesEnum.USER_DETAIL },
                class: this.$route.name === this.tabs?.[i++].to.name ? 'p-highlight' : ''
            }
        ];

        if(!this.isNew){
            tabs.push({
                label: "User ledgers",
                icon: 'fas fa-money-bill',
                to: { name: UsersRoutesEnum.USER_INVOICES },
                class: this.$route.name === this.tabs?.[i++].to.name ? 'p-highlight' : ''
            })
        }

        return tabs
    }

    get isNew() {
        return this.userId === 'new';
    }

    get backRoute() {
        return {name: UsersRoutesEnum.USERS_LIST};
    }

     get isBroker() {
        return authStore.getters.me.role == UserRoleEnum.BROKER;
    }
    
    get isCustomer() {
        return authStore.getters.me.role == UserRoleEnum.CUSTOMER;
    }
    
    get isClient() {
        return authStore.getters.me.role == UserRoleEnum.CLIENT;
    }
    
    get isSuperAdmin() {
        return authStore.getters.me.role == UserRoleEnum.SUPER_ADMIN;
    }

    get isReadOnly() {
        return this.isBroker;
    }

    async loadUser() {
        this.$waitFor(
            async () => {
                this.user = await usersService.getById(+this.userId)
                this.selectedState = this.user.state;
            }
        );
    }

    private deleteUser() {
        const message = this.$t(
            'user.delete_message',
            {name: `${this.user.name} ${this.user.surname}`}
        );

        const header = this.$t('message.confirm');

        this.$confirmMessage(message, header)
            .then((r) => {
                if (r) {

                    this.$waitFor(async () => {
                        await usersService.deleteBySystem(this.user);

                        this.$router
                            .replace(this.backRoute)
                            .then(() => this.$successMessage("User successfully deleted"));
                    });
                }
            });
    }

    private async uploadFiles(userId: number) {
        return await usersService.uploadFiles(
            userId,
            {
                insuranceFile: this.user.insuranceFileToUpload,
                licenseFile: this.user.licenseFileToUpload,
                avatarImage: this.user.avatarFileToUpload,
                truckImage: this.user.truckFileToUpload
            }
        )
    }

    async onSave(): Promise<any> {
        if (!this.user.broker) {
            this.$errorMessage("Broker is required")
            return;
        }

        this.user.broker_id = this.user.broker.id

        this.$waitFor(
            async () => {
                this.user.state = this.selectedState as State;
                if (this.user.state)
                    this.user.state_id = this.user.state.id;
                else
                    this.user.state_id = null;

                const isFileToUpload = [
                    this.user.licenseFileToUpload,
                    this.user.insuranceFileToUpload,
                    this.user.avatarFileToUpload,
                    this.user.truckFileToUpload,
                ].some(x => !!x);

                if (this.isNew) {
                    const response = await usersService.create(this.user);

                    if (isFileToUpload) {
                        await this.uploadFiles(response.id);
                        this.$successMessage("Files uploaded")
                    }

                    this.user = {...this.user, ...response};

                    this.$successMessage("User successfully created");
                } else {
                    await usersService.updatePatch(this.user);

                    if (isFileToUpload) {
                        await this.uploadFiles(+this.userId);
                        this.$successMessage("Files uploaded")
                    }

                    this.$successMessage("User successfully updated");
                }

                this.$router.back();
            },

            "Saving failed"
        );
    }

    onDelete() {
        this.deleteUser();
    }

    mounted() {
        if (!this.isNew) {
            this.loadUser();
        } else {
            this.user = new User();
        }
    }
}
