import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, KeepAlive as _KeepAlive } from "vue"

const _hoisted_1 = { class: "card page--limited" }
const _hoisted_2 = { class: "p-d-flex p-ai-center" }
const _hoisted_3 = { class: "p-d-flex p-ai-center" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  class: "p-d-flex p-ai-center"
}
const _hoisted_7 = { class: "p-p-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SplitButton = _resolveComponent("SplitButton")!
  const _component_SendMessageDialog = _resolveComponent("SendMessageDialog")!
  const _component_TabMenu = _resolveComponent("TabMenu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _directive_tooltip = _resolveDirective("tooltip")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, { to: _ctx.backRoute }, {
          default: _withCtx(() => [
            _withDirectives(_createVNode(_component_Button, {
              icon: "pi pi-arrow-left",
              class: "p-mb-0"
            }, null, 512), [
              [
                _directive_tooltip,
                _ctx.$t('back'),
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.isNew)
          ? _withDirectives((_openBlock(), _createElementBlock("h1", _hoisted_4, null, 512)), [
              [_directive_t, 'user.title_new']
            ])
          : _withDirectives((_openBlock(), _createElementBlock("h1", _hoisted_5, null, 512)), [
              [_directive_t, 'user.title_edit']
            ])
      ]),
      (!_ctx.isReadOnly)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_SplitButton, {
              label: "Save",
              icon: "pi pi-save",
              model: _ctx.actions,
              onClick: _ctx.onSave,
              disabled: _ctx.requestPending
            }, null, 8, ["model", "onClick", "disabled"]),
            _createVNode(_component_SendMessageDialog, {
              userId: _ctx.user?.id,
              visible: _ctx.displayMessageDialog,
              "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (_ctx.displayMessageDialog = $event))
            }, null, 8, ["userId", "visible"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_TabMenu, { model: _ctx.tabs }, null, 8, ["model"]),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({Component}) => [
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(), _createBlock(_KeepAlive, null, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
              userId: _ctx.userId,
              user: _ctx.user,
              onSave: _ctx.onSave
            }, null, 8, ["userId", "user", "onSave"]))
          ], 1024))
        ])
      ]),
      _: 1
    })
  ]))
}